<template>
  <div
    :id="`${modalId}`"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-hidden="true"
  >
    <div
      class="modal-dialog sx-modal-responsive"
      style="width: 90% !important; min-width: 90% !important"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="designedItems.length">
            <i class="fas fa-plus"></i>&nbsp;
            {{
              this.$t(
                "Set.NewFormat",
                {},
                { locale: this.$store.state.activeLang }
              ).replaceAll("OBJECT_NAME", this.pageData.name)
            }}
          </h5>
          <h5 v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" :class="{ 'p-0': designedItems.length }">
          <SetLoader
            :isDocumentTitleChange="false"
            v-if="!designedItems.length"
          />
          <form class="form w-100" id="newForm">
            <div class="container-fluid">
              <div class="row">
                <div class="col">
                  <AlertBox type="warning" :messages="formError" />
                  <ValidationMessageList
                    v-if="crudResponseValidationModel.showValidations"
                    :isModal="false"
                    :msg="crudResponseValidationModel.msg"
                    :message="crudResponseValidationModel.message"
                    :errors="crudResponseValidationModel.errors"
                    :warnings="crudResponseValidationModel.warnings"
                    :informations="crudResponseValidationModel.informations"
                  />
                </div>
              </div>
            </div>
            <HiddenFields crudType="Tree" :pageData="pageData" />
            <DesignItem
              v-for="element in designedItems"
              :key="element.id"
              :element="element"
              :items="designedItems"
            />
          </form>
        </div>
        <div class="modal-footer">
          <template v-if="designedItems.length">
            <button
              @click="save('btn-tree-record-save')"
              type="button"
              class="btn btn-success btn-tree-record-save me-2"
            >
              <span>
                <i class="fa fa-save"></i>
                {{
                  $t(
                    "BaseModelFields.Save",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</span
              >
            </button>
          </template>
          <button
            type="button"
            class="btn btn-danger btn-sm"
            data-bs-dismiss="modal"
          >
            <i class="fas fa-times"></i>
            {{
              $t(
                "BaseModelFields.Close",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <ValidationMessageListInModal
    :windowOpener="windowOpener"
    :errors="crudResponseValidationModel.modalErrors"
    :warnings="crudResponseValidationModel.modalWarnings"
    :informations="crudResponseValidationModel.modalInformations"
    :businessRuleErrors="crudResponseValidationModel.businessRuleErrors"
    :businessRuleResponse="crudResponseValidationModel.businessRuleResponse"
  />
</template>
<script>
import ValidationMessageList from "@/components/custom/validation-message/List.vue";
import ValidationMessageListInModal from "@/components/custom/validation-message/Modal.vue";

import { hideModal, showModal } from "@/core/helpers/dom";
import $ from "jquery";
import { createToast } from "mosha-vue-toastify";
import HiddenFields from "@/components/set-design/form-items/HiddenFields.vue";
import DesignItem from "@/components/set-design/DesignItem.vue";
export default {
  name: "TreeNewRecordModal",
  props: ["pageData", "designedItems"],
  emits: ["recordCreated"],
  watch: {
    pageData() {
      this.formError = [];
      this.crudResponseValidationModel = {};
      $("form#newForm").removeClass("was-validated");
    },
  },
  data() {
    return {
      modalId: "treeNewRecordModal",
      model: {},
      formError: [],
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "BaseModelFields.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-tree-record-save",
      crudResponseValidationModel: {},
    };
  },
  components: {
    DesignItem,
    HiddenFields,
    ValidationMessageList,
    ValidationMessageListInModal,
  },
  methods: {
    save(buttonName) {
      var form = $("form#newForm"),
        allFields = this.pageData.fields.find(
          (f) => f.isMainObject
        ).fieldModels;

      this.model = this.$root.modelCreate("form#newForm");

      this.crudResponseValidationModel = {
        showValidations: false,
        msg: "",
        message: "",
        errors: [],
        modalErrors: [],
        warnings: [],
        modalWarnings: [],
        informations: [],
        modalInformations: [],
        businessRuleResponse: {},
        businessRuleErrors: [],
      };

      if (!form[0].checkValidity() || !this.$root.forceFormValidation(form)) {
        form.addClass("was-validated");

        this.$root.elementScrollToTop($(`#${this.modalId}`));
        this.crudResponseValidationModel.showValidations = true;
        this.crudResponseValidationModel.errors = this.$root.formValidation(
          form,
          this.model.values,
          allFields
        );

        return;
      }

      var button = $(`.${buttonName}`),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses),
        self = this;

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.formError = [];
      this.model.values = this.model.values.map(function (m) {
        return { key: m.key, value: m.value };
      });
      this.$appAxios
        .post("/rws-SetController-New", this.model)
        .then((response) => {
          var result = response.data,
            businessRuleResponse = result.businessRuleResponse,
            businessRuleErrors = businessRuleResponse.businessRuleResponses,
            errors = this.$root.getValidationMessages(
              result.validations,
              false,
              1,
              allFields
            ),
            modalErrors = this.$root.getValidationMessages(
              result.validations,
              true,
              1,
              allFields
            ),
            warnings = this.$root.getValidationMessages(
              result.validations,
              false,
              2,
              allFields
            ),
            modalWarnings = this.$root.getValidationMessages(
              result.validations,
              true,
              2,
              allFields
            ),
            informations = this.$root.getValidationMessages(
              result.validations,
              false,
              3,
              allFields
            ),
            modalInformations = this.$root.getValidationMessages(
              result.validations,
              true,
              3,
              allFields
            );

          this.crudResponseValidationModel.businessRuleResponse =
            businessRuleResponse;

          if (
            businessRuleErrors.length > 0 &&
            businessRuleResponse.notificationType !== "Tooltip"
          ) {
            this.crudResponseValidationModel.businessRuleErrors =
              businessRuleErrors;
          }

          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          form.removeClass("was-validated");

          if (result.isOk) {
            createToast(
              this.$t(
                "BaseModelFields.SuccessMessage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: true,
                position: "top-right",
                type: "success",
                transition: "zoom",
              }
            );

            if (informations.length > 0) {
              informations.forEach((error) => {
                this.crudResponseValidationModel.modalInformations.push(error);
              });
            }

            if (modalInformations.length > 0) {
              modalInformations.forEach((error) => {
                this.crudResponseValidationModel.modalInformations.push(error);
              });
            }

            this.$emit("recordCreated", result);
            hideModal(document.getElementById(`${this.modalId}`));
          } else {
            this.$root.elementScrollToTop($(`#${this.modalId}`));

            this.crudResponseValidationModel.msg = result.msg;
            this.crudResponseValidationModel.message = result.message;
            this.crudResponseValidationModel.errors = errors;
            this.crudResponseValidationModel.modalErrors = modalErrors;
            this.crudResponseValidationModel.warnings = warnings;
            this.crudResponseValidationModel.modalWarnings = modalWarnings;
            this.crudResponseValidationModel.informations = informations;
            this.crudResponseValidationModel.modalInformations =
              modalInformations;

            //unknown error
            if (
              String.isNullOrWhiteSpace(this.crudResponseValidationModel.msg) &&
              String.isNullOrWhiteSpace(
                this.crudResponseValidationModel.message
              ) &&
              this.crudResponseValidationModel.errors.length == 0 &&
              this.crudResponseValidationModel.warnings.length == 0 &&
              this.crudResponseValidationModel.informations.length == 0 &&
              this.crudResponseValidationModel.modalErrors.length == 0 &&
              this.crudResponseValidationModel.modalWarnings.length == 0 &&
              this.crudResponseValidationModel.modalInformations.length == 0
            ) {
              this.crudResponseValidationModel.msg = this.$t(
                "BaseModelFields.AnUnknownErrorHasOccurred",
                {},
                { locale: this.$store.state.activeLang }
              );
            }

            this.crudResponseValidationModel.showValidations =
              !String.isNullOrWhiteSpace(
                this.crudResponseValidationModel.msg
              ) ||
              !String.isNullOrWhiteSpace(
                this.crudResponseValidationModel.message
              ) ||
              this.crudResponseValidationModel.errors.length > 0 ||
              this.crudResponseValidationModel.warnings.length > 0 ||
              this.crudResponseValidationModel.informations.length > 0;
          }

          this.crudResponseValidationModel.businessRuleResponse =
            businessRuleResponse;

          var modalShow =
            this.crudResponseValidationModel.businessRuleErrors.length > 0 ||
            this.crudResponseValidationModel.modalErrors.length > 0 ||
            this.crudResponseValidationModel.modalWarnings.length > 0 ||
            this.crudResponseValidationModel.modalInformations.length > 0;
          if (modalShow) {
            showModal(document.getElementById("modalValidationMessage"));
          }
        })
        .catch(function (error) {
          // alert(error);
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          self.formError.push(error);
        });
    },
  },
};
</script>
